import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useLang, useApp, useForm, useValidator, useAlert, useTheme} from 'library/hooks';
import {Input, Button, Columns} from 'library/components';
import {numberFormat} from 'library/helpers';

import dimensionsRule from 'helpers/dimensionsRule';

import Fields from './Fields';
import Volume from './Volume';

const Title = styled.h1(props => `

    font-size: 1.25em;
    color: ${props.theme.backgroundColor.main.normal};
    padding-bottom: ${props.theme.padding.smaller};
`);

const Container = styled.div(props => `

    z-index: 1;
    position: fixed;
    left: 25%;
    top: 25%;
    transform: translate(-17%, 25%);
    padding-top: ${props.theme.padding.bigger};
    padding-right: ${props.theme.padding.bigger};
    padding-bottom: ${props.theme.padding.normal};
    padding-left: ${props.theme.padding.bigger};
    background: ${props.theme.backgroundColor.grays.white};
    border: 1px solid ${props.theme.borderColor.main.normal};
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
    border-radius: ${props.theme.borderRadius.normal};

    @media (min-width: ${props.theme.minWidth.tablet}){

        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`);

export default function DimensionsModal(){

    const [lang] = useLang('dimensions_modal');
    const [modal, setModal] = useApp('dimensions.isOpen', false);
    const [getForm, setForm] = useForm();
    const [getErrors, getMessages, setRules] = useValidator(getForm(), true);
    const setAlert = useAlert();
    const theme = useTheme();

    const width = getForm('width');
    const height = getForm('height');
    const length = getForm('length');

    const volume = useMemo(() => countDimensions(width, height, length), [width, height, length]);

    function submit(e){

        e.preventDefault();

        console.log('Submit form!');

        setRules(validator => {

            validator.add('isValidDimensions', dimensionsRule);

            validator.set('width').isFilled(lang.width_is_filled).isInt(lang.width_is_int).isValidDimensions(lang.width_is_valid_parcel);
            validator.set('height').isFilled(lang.height_is_filled).isInt(lang.height_is_int).isValidDimensions(lang.height_is_valid_parcel);
            validator.set('length').isFilled(lang.length_is_filled).isInt(lang.length_is_int).isValidDimensions(lang.length_is_valid_parcel);
        });

        if(getErrors().size !== 0){

            setAlert(getMessages());
        }

        else {

            setAlert(lang.valid_dimensions);
        }
    }

    function countDimensions(width, height, length){

        if(width === '' || height === '' || length === ''){

            return false;
        }

        const volume = parseInt(width) * parseInt(height) * parseInt(length) / 1000000;

        if(isNaN(volume)){

            return false;
        }

        return numberFormat(volume, 2, ',');
    }

    if(modal === false){

        return false;
    }

    return (

        <Container>

            <Title>{lang.title}</Title>

            <div>{lang.text1}</div>
            <div>{lang.text2}</div>
            <div>{lang.text3}</div>
            <div>&nbsp;</div>

            <form onSubmit={submit}>

                <Fields>

                    <Fields.item>

                        <Input label={lang.width} name="width" value={getForm('width')} onChange={setForm} error={getErrors('width')} maxlength="3" />

                    </Fields.item>

                    <Fields.item>

                        <Input label={lang.height} name="height" value={getForm('height')} onChange={setForm} error={getErrors('height')} maxlength="3" />

                    </Fields.item>

                    <Fields.item>

                        <Input label={lang.length} name="length" value={getForm('length')} onChange={setForm} error={getErrors('length')} maxlength="3" />

                    </Fields.item>

                </Fields>

                {volume !== false && true === false && <Volume>{lang.volume} {volume} m³</Volume>}

                <Columns>

                    <Columns.left>

                        <Button onClick={() => setModal(false)} styled={{marginBottom: theme.margin.normal}}>{lang.close_modal}</Button>

                    </Columns.left>

                    <Columns.right>

                        <Button styled={{marginBottom: theme.margin.normal}}>{lang.check_dimensions}</Button>

                    </Columns.right>

                </Columns>

            </form>

        </Container>
    );
}
