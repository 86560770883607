import React from 'react';
import styled from 'styled-components';

const Label = styled.div(props => `

    padding-bottom: ${props.theme.padding.normal};
    ${props.error !== false && `color: ${props.theme.color.negative.normal}`}
`);

const StyledTextarea = styled.textarea.attrs(props => ({

    marginBottom: props.styled.marginBottom || props.theme.margin.normal,
    width: props.styled.width || '100%',
    height: props.styled.width || '10em'
}))`

    ${props => `

        width: ${props.width};
        height: ${props.height};
        padding: ${props.theme.padding.smaller};
        border: 1px solid ${props.theme.borderColor.main.normal};
        border-radius: ${props.theme.borderRadius.smaller};
        margin-bottom: ${props.marginBottom};
        outline: 0;
        resize: none;
    `}
`;

function Textarea(props){

    function change(e){

        const input = {

            name: props.name,
            value: e.target.value
        };

        return props.onChange(input);
    }

    return(

        <React.Fragment>

            <Label error={props.error}>{props.label}:</Label>
            <StyledTextarea styled={props.styled} name={props.name} onChange={change} value={props.value} />
        
        </React.Fragment>
    );
}

Textarea.defaultProps = {

    styled: {},
    error: false
};

export default Textarea;
