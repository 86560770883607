import {useContext} from 'react';
import AppContext from 'library/AppContext';

function useLang(key, type){

    if(type === undefined){

        type = 'component';
    }

    else if(type !== 'component' && type !== 'app'){

        throw new Error('Type has to be component or app');
    }

    const context = useContext(AppContext);
    const [componentTranslations, appTranslations, lang, setLang] = context.langify;

    const translations = type === 'component' ? componentTranslations[key] : appTranslations[key];

    return [translations, lang, setLang];
}

export default useLang;
