import React from 'react';
import styled from 'styled-components';
import Button from 'library/components/Button';
import {useLang} from 'library/hooks';

const Container = styled.div(props => `

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    z-index: 1000;
    padding: ${props.theme.padding.bigger};
`);

const Box = styled.div(props => `

    background: ${props.theme.backgroundColor.grays.white};
    padding: ${props.theme.padding.normal};
    border-radius: ${props.theme.borderRadius.normal};
    max-height: 100%;
    overflow: auto;
`);

const Message = styled.div(props => `

    padding-bottom: ${props.theme.padding.normal};
    text-align: center;
`);

function Alert(props){

    const [lang] = useLang('alert_modal', 'app');

    if(props.value === false){

        return false;
    }

    let message = props.value;

    if(Array.isArray(message) === false){

        message = [props.value];
    }

    return (

        <Container>

            <Box>

                {message.map((v, k) => <Message key={k}>{v}</Message>)}
                <Button onClick={() => props.close()}>{lang.ok}</Button>

            </Box>

        </Container>
    );
}

export default Alert;
