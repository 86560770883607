import {parseNumber, sort} from 'library/helpers';

export default function dimensionsRule(validator, rule, message){

    return validator.rule(() => {

        const width = parseNumber(validator.data.get('width'));
        const height = parseNumber(validator.data.get('height'));
        const length = parseNumber(validator.data.get('length'));

        if(width !== 0 && height !== 0 && length !== 0){

            const dimensions = sort([width, height, length], 'desc');

            const sideA = dimensions[0]; // Longest
            const sideB = dimensions[1];
            const sideC = dimensions[2];

            const ribbonlength = sideA + sideB * 2 + sideC * 2;

            if(sideA > 200 || sideB > 80 || ribbonlength > 300){

                validator.setError(rule, message);
            }
        }
    });
}
