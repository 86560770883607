import {useContext} from 'react';
import AppContext from 'library/AppContext';

function useAlert(){

    const context = useContext(AppContext);

    function setAlert(text, callback){

        context.alertModal(text, callback);
    }

    return setAlert;
}

export default useAlert;
