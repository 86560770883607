import {useContext} from 'react';
import AppContext from 'library/AppContext';

function useStores(key, filter){

    const context = useContext(AppContext);

    if(key === undefined){

        return context.stores;
    }

    const store = context.side + '/' + key;

    const [stores, dispatch] = context.stores;

    function dispatcher(action){

        action.store = store;

        dispatch(action);
    }

    let data;

    if(filter !== undefined){

        let key = false;

        for(let i in filter){
            
            // Set key to filter by

            key = i; 
        }

        data = new Map();

        stores[store].forEach((v, k) => {

            // Add only matching items to return data

            if(v[key] === filter[key]){

                data.set(k, v);
            }
        });
    }

    else {

        // If filter is not used return all items

        data = stores[store];
    }

    return [data, dispatcher];
}

export default useStores;
