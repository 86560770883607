import {useContext} from 'react';
import AppContext from 'library/AppContext';

function useApp(namespace, initialState){

    const context = useContext(AppContext);
    const current = {state: context.sharedState.state};

    if(initialState !== undefined && current.state.get(namespace) === undefined){

        current.state.set(namespace, initialState);
    }

    function setState(state){

        current.state.set(namespace, state);
       
        context.sharedState.setState(current.state);
    }

    return [current.state.get(namespace), setState];
}

export default useApp;
