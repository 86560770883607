import React from 'react';
import styled from 'styled-components';

import nordea from 'library/images/paytrail/nordea.png';
import osuuspankki from 'library/images/paytrail/osuuspankki.png';
import danskeBank from 'library/images/paytrail/danskebank.png';
import alandsbanken from 'library/images/paytrail/alandsbanken.png';
import handelsbanken from 'library/images/paytrail/handelsbanken.png';
import paypal from 'library/images/paytrail/paypal.png';
import sPankki from 'library/images/paytrail/spankki.png';
import jousto from 'library/images/paytrail/jousto.png';
import aktia from 'library/images/paytrail/aktia.png';
import popPankki from 'library/images/paytrail/pop.png';
import omaSaastopankki from 'library/images/paytrail/omasp.png';
import visa from 'library/images/paytrail/visa.png';
import mastercard from 'library/images/paytrail/master.png';
import diners from 'library/images/paytrail/diners.png';
import americanExpress from 'library/images/paytrail/american-express.png';
import mobilepay from 'library/images/paytrail/mobilepay.png';
import collector from 'library/images/paytrail/collector.png';
import saastopankki from 'library/images/paytrail/sp.png';

const Container = styled.div(() => `

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`);

const Form = styled.form(props => `

    padding: ${props.theme.padding.normal};
`);

const Image = styled.input(() => `

    display: block;
    outline: 0;
`);

const banks = {

    1: {logo: nordea, name: 'Nordea'},
    2: {logo: osuuspankki, name: 'Osuuspankki'},
    3: {logo: danskeBank, name: 'Danske Bank'},
    5: {logo: alandsbanken, name: 'Ålandsbanken'},
    6: {logo: handelsbanken, name: '// Handelsbanken'}, 
    9: {logo: paypal, name: 'Paypal'},
    10: {logo: sPankki, name: 'S-Pankki'}, 
    18: {logo: jousto, name: 'Jousto'}, 
    50: {logo: aktia, name: 'Aktia'}, 
    51: {logo: popPankki, name: 'POP Pankki'}, 
    52: {logo: saastopankki, name: 'Säästöpankki'},
    53: {logo: visa, name: 'Visa'}, // Nets 
    54: {logo: mastercard, name: 'MasterCard'}, // Nets
    55: {logo: diners, name: 'Diners Club'}, // Nets
    56: {logo: americanExpress, name: 'American Express Nets'},
    58: {logo: mobilepay, name: 'MobilePay'},
    60: {logo: collector, name: 'Collector Bank'}, 
    61: {logo: omaSaastopankki, name: 'Oma Säästöpankki'}
};

export default props => {

    if(props === undefined){

        throw new Error('Props is not defined');
    }

    if(props.data == undefined){

        throw new Error('Data is not defined');
    }

    if(props.data.action === undefined){

        throw new Error('Action is not defined');
    }

    if(props.data.forms === undefined){

        throw new Error('Forms is not defined');
    }

    let wait = true;

    function submit(e){

        if(wait === true){

            if(props.onSubmit !== undefined){

                // Run come code before submit
    
                e.preventDefault();
                props.onSubmit(e.target);
            }

            wait = false;
        }
    }

    return (

        <Container>

            {props.data.forms.map((fields, k) => {

                const inputs = [];

                for(let name in fields){

                    inputs.push(<input key={name} type="hidden" name={name} value={fields[name]} />);
                }

                const bank = banks[fields['PAYMENT_METHODS']];

                return (

                    <Form key={k} action={props.data.action} method="POST" onSubmit={submit}>

                        <Image type="image" src={bank.logo} alt={bank.name} />
                        {inputs}

                    </Form>
                );
            })}

        </Container>
    );
}
