import React from 'react';
import styled from 'styled-components';

const Container = styled.div(props => `

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: ${props.theme.backgroundColor.grays.light};
    padding-top: ${props.paddingTop !== undefined ? props.paddingTop : `5em`};
    padding-right: ${props.theme.padding.normal};
    padding-bottom: ${props.theme.padding.normal};
    padding-left: ${props.theme.padding.normal};
    box-shadow: ${props.theme.boxShadow.left};
    overflow: auto;
    animation: slide 0.25s;

    @media (min-width: ${props.theme.minWidth.tablet}){

        width: ${props.width !== undefined ? props.width : `50%`};
    }

    @keyframes slide {

        from {

            margin-right: -${props.width !== undefined ? props.width : `50%`};
        }

        to {

            margin-right: 0;
        }
    }
`);

function Sidepanel(props){

    return <Container {...props.styled}>{props.children}</Container>;
}

export default Sidepanel;
