import {useContext} from 'react';
import AppContext from 'library/AppContext';

function useRoute(){

    const context = useContext(AppContext);

    return [context.route.setRoute, context.route.route];
}

export default useRoute;
