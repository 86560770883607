export default (key, value, data) => {

    let item = false;

    if(typeof data.forEach === 'function'){

        data.forEach(v => {

            if(v[key] === value){

                item = v;
            }
        });
    }

    return item;
}
