import React from 'react';
import styled from 'styled-components';

const Container = styled.div`

    display: flex;
    align-items: center;
    height: 100%;
`;

const Item = styled.div`

    width: 100%;
`;

function Middle(props){

    return (

        <Container>

            <Item>{props.children}</Item>

        </Container>
    );
}

export default Middle;
