import React from 'react';
import styled from 'styled-components';

const Container = styled.div(props => `
        
    background: linear-gradient(${props.theme.backgroundColor.main.light}, ${props.theme.backgroundColor.main.normal});
    border: 1px solid ${props.theme.backgroundColor.main.normal};
    color: ${props.theme.color.main.normal};
    padding: ${props.theme.padding.normal};
    border-radius: ${props.theme.borderRadius.normal} ${props.theme.borderRadius.normal} 0 0;
    ${props.marginTop !== undefined ? `margin-top: ${props.marginTop};` : ``}
`);

function Title(props){

    return <Container {...props.styled}>{props.children}</Container>;
}

export default Title;
