import React from 'react';
import styled from 'styled-components';

const Container = styled.div(props => `

    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(${props.theme.backgroundColor.main.light}, ${props.theme.backgroundColor.main.normal});
    color: ${props.theme.color.sub.normal};
`);

function Loading(){

    return (

        <Container><i className="fas fa-cog fa-spin fa-3x" /></Container>
    );
}

export default Loading;
