import {Preloading, Alert, Confirm} from 'library/components';
import Loading from 'components/Loading';

const config = {

    defaultTheme: 'public',

    redirect: {

        forbidden: path => {

            if(path.match(/\/admin\//i) !== null){

                return '/admin/login.html';
            }

            return '/shop/';
        }
    },

    components: {

        loading: Loading,
        preloading: Preloading,
        alert: Alert,
        confirm: Confirm
    },

    endpoints: {

        privileges: {

           endpoint: '/api/public/privileges/',
           method: 'get'
        },

        refreshAccessToken: {

            endpoint: '/api/public/refresh_access_token/',
            method: 'get'
        }
    }
};

export default config;
