import React from 'react';
import styled from 'styled-components';

const Label = styled.div(props => `

    padding-bottom: ${props.theme.padding.normal};
    ${props.error !== false && `color: ${props.theme.color.negative.normal}`}
`);

const Container = styled.div(props => `

    display: flex;
    ${props.flexDirection !== undefined ? `flex-direction: ${props.flexDirection};` : ``}
    justify-content: space-around;

    // Padding of labels

    & > div > div:last-of-type {
        
        padding: 0 ${props.theme.padding.normal} 0 ${props.theme.padding.normal};
    }

    // Last label without padding right

    & > div:last-of-type > div:last-of-type {
        
        padding-right: 0;
    }
`);

const Item = styled.div(props => `

    display: flex;
    align-items: center;
    margin-bottom: ${props.marginBottom !== undefined ? props.marginBottom : props.theme.margin.normal};
`);

const Switch = styled.div.attrs(props => ({

    borderRadius: props.theme.borderRadius.radio || '1.1em'
}))`

    ${props => `

        display: flex;
        flex-basis: ${props.theme.width.normal};
        align-items: center;
        justify-content: center;
        width: ${props.theme.width.normal};
        height: ${props.theme.height.normal};
        border-radius: ${props.borderRadius};
        background: #fff;
        border: 1px solid ${props.theme.borderColor.main.normal};
        cursor: pointer;
    `}
`;

const Selected = styled.div(props => `

    display: ${props.checked === true ? `block` : `none`};
    width: 0.6em;
    height: 0.6em;
    border-radius: 0.3em;
    background: black;
`);

const Description = styled.div(() => `

    width: 100%;
`);

function Radio(props){

    function isChecked(v){

        if(v === props.value){

            return true;
        }

        return false;
    }

    function toggle(v){

        props.onChange({name: props.name, value: v});
    }

    return (

        <React.Fragment>

            {props.label !== undefined && <Label error={props.error}>{props.label}:</Label>}

            <Container {...props.styled}>

                {props.options.map((v, k) => {

                    return (

                        <Item key={k}>

                            <div>                            

                                <Switch onClick={() => toggle(v.value)}>

                                    <Selected checked={isChecked(v.value)}></Selected>

                                </Switch>

                            </div>

                            <Description>{v.label}</Description>

                        </Item>
                    );
                })}

            </Container>

        </React.Fragment>
    );
}

Radio.defaultProps = {

    error: false
}

export default Radio;
