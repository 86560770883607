import React from 'react';
import DimensionsModal from 'components/public/basic-details/DimensionsModal';

function Modals(){

    return (

        <DimensionsModal />
    );
}

export default Modals;
