import {parseNumber} from 'library/helpers';

export default function round(v, precision){

    if(precision === undefined){

        precision = 0;
    }

    let number = '1';

    for(let i = 0; i < precision; i++){

        number += '0';
    }

    number = parseNumber(number);

    let result = Math.round((parseNumber(v) + Number.EPSILON) * number) / number;

    return result;
}
