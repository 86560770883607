import {useContext} from 'react';
import AppContext from 'library/AppContext';

function useLoading(){

    const context = useContext(AppContext);

    return [context.loading.setLoading, context.loading.state];
}

export default useLoading;
