export default function(v){

    if(v === undefined || v === null || v === ''){

        return false;
    }

    if(typeof v.toString !== "function"){

        return false;
    }

    // Value is handle validated without spaces (unformatted and untrimmed)

    v = v.toString().replace(new RegExp(' ', 'g'), '');

    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '.', '-'];
    const chars = v.split('');

    let numeric = true;
    let commas = 0;
    let dots = 0;

    for(let i = 0; i < chars.length; i++){

        if(chars[i] === ','){

            commas++;
        }
        
        if(chars[i] === '.'){

            dots++;
        }

        // Only first char can be hyphen (negative number)

        if(i !== 0 && chars[i] === '-'){

            numeric = false; break;
        }

        // First or last char cannot be comma or dot

        if((i === 0 || i === chars.lenght - 1) && (chars[i] === ',' || chars[i] === '.')){

            numeric = false; break;
        }

        // Only one comma or dot can be used as delimeter limiter

        if(commas + dots > 1){

            numeric = false; break;
        }

        // Char has to be found on array

        if(numbers.indexOf(chars[i]) === -1){
        
            numeric = false; break;
        }
    }

    return numeric;
}
