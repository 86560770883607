import React from 'react';
import ReactDOM from 'react-dom';
import App, {route} from 'library/App';
import Modals from 'components/Modals';

function Main(){

    //route('/@public/frontpage/FrontpageView');
    route('/@public/basic-details/BasicDetailsView', 'countries@getAll');
    route('/fi@public/basic-details/BasicDetailsView', 'countries@getAll');
    route('/en@public/basic-details/BasicDetailsView', 'countries@getAll');
    //route('/shop/orders/form/one.html@public/basic-details/BasicDetailsView', 'countries@getAll');
    route('/shop/orders/form/two.html@public/advanced-details/AdvancedDetailsView');
    route('/shop/terms_of_use.html@public/usage-terms/UsageTermsView');
    route('/shop/orders/:id/form/three.html@public/payment/PaymentView', ['purchases@getSingle', 'countries@getAll']);
    route('/shop/orders/:id/form/four.html@public/summary/SummaryView', ['purchases@getSingle', 'countries@getAll']);
    route('/shop/orders/:id@public/summary/SummaryView', ['purchases@getSingle', 'countries@getAll']);

    return <App modals={Modals} />
}

ReactDOM.render(<Main />, document.getElementById('root'));
