export default function merge(target, source){

    let targetType = 'object';

    if(target instanceof Map === true){

        targetType = 'Map';
        target = convertInto(target, 'object');
    }

    if(source instanceof Map === true){

        source = convertInto(source, 'object');
    }

    function convertInto(v, type){

        if(type === 'object'){

            return Object.fromEntries(v);
        }

        if(type === 'Map'){

            const tmp = new Map();

            for(let k in v){

                tmp.set(k, v[k]);
            }

            return tmp;
        }
    }

    const merged = Object.assign(target, source);

    // Return merged result as original target type

    if(targetType === 'object'){

        return merged;
    }

    else if(targetType === 'Map'){

        return convertInto(merged, 'Map');
    }
}
