import React from 'react';
import {useLang, useTheme} from 'library/hooks';
import {Button} from 'library/components';
import styled from 'styled-components';

const Container = styled.div(() => `

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    z-index: 1000;
`);

const Box = styled.div(props => `

    background: ${props.theme.backgroundColor.grays.white};
    padding: ${props.theme.padding.normal} ${props.theme.padding.normal} 0 ${props.theme.padding.normal};
    border-radius: ${props.theme.borderRadius.normal};
`);

const Text = styled.div(props => `

    padding-bottom: ${props.theme.padding.normal};
    text-align: center;
`);

const Columns = styled.div(props => `

    display: flex;
    flex-wrap: wrap;

    @media (min-width: ${props.theme.minWidth.tablet}){

        flex-wrap: nowrap;

        & > button:first-of-type {

            margin-right: ${props.theme.margin.smaller !== undefined ? props.theme.margin.smaller : `0.5em`};
        }

        & > button:last-of-type {

            margin-left: ${props.theme.margin.smaller !== undefined ? props.theme.margin.smaller : `0.5em`};
        }
    }
`);

function Confirm(props){

    const [lang] = useLang('confirm_modal', 'app');
    const theme = useTheme();

    if(props.value === false){

        return false;
    }

    let ok = lang.ok;

    if(props.lang !== undefined && props.lang.ok !== undefined){

        ok = props.lang.ok;
    }

    return (

        <Container>

            <Box>

                <Text>{props.value}</Text>

                <Columns>

                    <Button onClick={() => props.close(false)} styled={{marginBottom: theme.margin.normal}}>{lang.cancel}</Button>
                    <Button onClick={() => props.close(true)} styled={{marginBottom: theme.margin.normal}}>{ok}</Button> 

                </Columns>

            </Box>

        </Container>
    );
}

export default Confirm;
