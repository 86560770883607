import React from 'react';
import styled from 'styled-components';

const Container = styled.div(props => `

    display: flex;

    & > div {

        padding: 0 ${props.theme.padding.smaller} 0 ${props.theme.padding.smaller};
    }

    & > div:first-of-type {

        padding-left: 0;
    }

    & > div:last-of-type {

        padding-right: 0;
    }
`);

const Item = styled.div(() => ``);

function Fields(props){

    return <Container>{props.children}</Container>;
}

Fields.item = (props) => {

    return <Item>{props.children}</Item>;
}

export default Fields;
