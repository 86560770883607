import React from 'react';
import styled from 'styled-components';

const Container = styled.div(props => `

    padding: ${props.theme.padding.normal};
    background: ${props.theme.backgroundColor.main.normal};
    color: ${props.theme.color.grays.white};
    margin-bottom: ${props.theme.margin.normal};
    border-radius: ${props.theme.borderRadius.smaller};
    text-align: center;
`);

function Volume(props){

    return (

        <Container>{props.children}</Container>
    );
}

export default Volume;
