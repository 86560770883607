import React from 'react';
import styled from 'styled-components';

const Container = styled.div(props => `

    display: flex;
    align-items: center;
    ${props.paddingBottom !== undefined ? `padding-bottom: ${props.paddingBottom};` : ``}
    ${props.marginBottom !== undefined ? `margin-bottom: ${props.marginBottom};` : ``}
`);

const Label = styled.div(props => `

    padding-left: ${props.theme.padding.normal};
    ${props.error !== false ? `color: ${props.theme.color.negative.normal}` : ``}
`);

const Box = styled.div`

    ${props => `

        display: flex;
        align-items: center;
        justify-content: center;
        min-width: ${props.theme.width.normal};
        width: ${props.theme.width.normal};
        height: ${props.theme.height.normal};
        min-height: ${props.theme.height.normal};
        border-radius: ${props.theme.borderRadius.smaller};
        background: #fff;
        border: 1px solid ${props.theme.borderColor.main.normal};
        cursor: pointer;
    `}
`;

function Component(props){

    const checked = props.checked === undefined || props.checked === false || props.checked === '' || props.checked === null ? false : true;

    function click(){

        const value = checked !== true ? props.value : props.unchecked;

        props.onChange({

            name: props.name,
            value: value
        });
    }

    return (
        
        <Container {...props.styled}>

            <Box onClick={click}>{checked === true && <i className="fas fa-check" />}</Box>
            {props.label !== undefined && <Label error={props.error}>{props.label}</Label>}

        </Container>
    );
}

Component.defaultProps = {

    value: true,
    unchecked: undefined,
    styled: {},
    error: false
}

export default Component;
